@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-checkbox {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  position: relative;
  padding-inline-start: 30px;
  user-select: none;
  width: fit-content;
}

.custom-checkbox input {
  display: none; /* Hide the default checkbox */
}

.checkmark {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  height: 20px;
  width: 20px;
  background-color: white; /* White background */
  border: 2px solid #ccc; /* Light gray border */
  border-radius: 3px; /* Slightly rounded corners */
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: white; /* White background when checked */
  border-color: #aaa; /* Darker gray border when checked */
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid gray; /* Gray tick */
  border-width: 0 2px 2px 0; /* Create a tick shape */
  transform: rotate(45deg); /* Rotate to form a tick */
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block; /* Show the tick when checked */
}
.react-tel-input {
  height: 54px;
  width: '100%';
  border-radius: 15px !important;
}
.react-tel-input input {
  border: none !important;
  height: 54px !important;
  border-radius: 15px !important;
  padding-left: 6px;
}
.flag-dropdown {
  background-color: white !important;
  border: none !important;
  border-radius: 15px !important;
  padding-left: 6px !important;
  margin-right: 30px !important;
}
/* for otp input */
.unique-otp-input-class
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiInputBase-input {
  height: 24px !important;
  width: 26px !important;
  border-radius: 15px !important;
  background-color: white !important;
}
.unique-otp-input-class
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 15px !important;
}
.MuiDataGrid-row {
  background-color: white !important;
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: #f4fef7 !important;
}
/* .MuiDataGrid-virtualScrollerRenderZone {
  gap: 16px !important;
} */
.MuiDataGrid-row {
  background-color: white !important;
  /* border-bottom: 1px solid #e0e0e0 !important;
  border-left: 1px solid #e0e0e0 !important ;
  border-right: 1px solid #e0e0e0 !important; */
}
.gradient-border {
  padding: 20px; /* Space for the border */
  background: linear-gradient(180deg, #c337b5 41.05%, #5046c0 100%);
  border-radius: 10px; /* Optional: adds rounded corners */
  position: relative;
  z-index: 1; /* Ensure content appears above */
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: -20px; /* Adjust based on border width */
  left: -20px; /* Adjust based on border width */
  right: -20px; /* Adjust based on border width */
  bottom: -20px; /* Adjust based on border width */
  background: linear-gradient(180deg, #c337b5 41.05%, #5046c0 100%);
  z-index: -1; /* Send it behind the content */
  border-radius: 10px; /* Optional: matches the container's corners */
}
.ql-container {
  min-height: 300px !important;
  border: none !important;
}
.ql-toolbar {
  border: none !important;
}
.oval {
  width: 95px; /* Adjust width */
  height: 120px; /* Adjust height */
  background-color: white; /* Change color as needed */
  border-radius: 50%; /* Makes the shape oval */
  display: flex; /* Center content if needed */
  justify-content: center; /* Center content if needed */
  align-items: flex-end; /* Center content if needed */
  overflow: hidden;
}
.Calendar {
  width: 100% !important;
  min-height: 100% !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.Calendar__day.-ltr {
  min-height: 30px !important;
}
.Calendar__day.-rtl {
  height: 30px !important;
}
.Calendar .Calendar__sectionWrapper {
  min-height: 220px !important;
}
.Calendar__weekDays {
  margin-bottom: 0px !important;
}
.Calendar__header {
  padding-inline: 20px !important;
  padding-block: 8px !important;
}
